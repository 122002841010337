  .preview-container {
    display: flex;
    justify-content: center;
    align-items: center;   
    flex-wrap: wrap;
    gap: 10px; 
  }

  .preview-item-container {
    position: relative;
    max-width: 100%;
    /* flex: 0 0 calc(33.33% - 10px); */
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
    border: 1px solid;
    border-radius: 6px;
  }

  .preview-item {
    max-width: 100%;
    flex: 0 0 calc(33.33% - 10px); 
    position: relative;
    width: 52px;
    height: 52px;
  }
  
  .preview-item img {
    max-width: 100%;
    /* height: auto; */
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: 6px;
  }
  .preview-item img:hover, .content-icon-documents:hover {
    opacity:  .5;
  }
  
  .remove-button {
    position: absolute;
    top: 0;
    right: 0;
    /* background-color: #c4c4c4; */
    background-color: transparent;
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
    padding: 0;
  }

  .remove-button svg {
    background-color: #00000078;
    border-radius: 3px;
  }
  
  .add-icon {
    font-size: 30px;
    cursor: pointer;
    color: #333;
  }


  .module-chat {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .media-selected {
    border: 4px solid #5bc0de;
    border-radius: 6px;
  }

  .blurred {
    filter: blur(35px);
  }

@media (min-width: 1280px){

  .preview-container .image-viewer img {
    /* height: 21rem !important; */
  }
  #content-view-pdf {
    height: 47vh !important;
  }
  #content-view-documents {
    width: 60% !important;
  }
}


@media (min-width: 1600px){
  .preview-container .image-viewer img {
    /* height: 28rem !important; */
  }
  #content-view-pdf {
    height: 51vh !important;
  }
  #content-view-documents {
    width: 50% !important;
  }
}

@media (min-width: 1777.78px){
  .preview-container .image-viewer img {
    height: 31rem !important;
  }
  #content-view-pdf {
    height: 54vh !important;
  }
}

@media (min-width: 1920px){
  /* Estilos para este rango de tamaño */
  .preview-container .image-viewer img {
    height: 36rem !important;
  }
}



/* EStilos para el contenedor de imagenes en grupo */
.image-group {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

#imgs-3.image-container {
  width: 30%;
}

.image-container {
  position: relative;
  width: 48%;
  height: 157px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5em;
  border-radius: 5px;
}

#reload-option {
  box-shadow: 0px 0px 4px 1px;
  background-color: #f5fafd;
  border-radius: 6px;
  margin-bottom: 0px; 
  font-weight: 100;
  font-size: 1rem
}
#reload-option:hover {
  background-color: #dcf2ff;
}
#reload-option.option-active {
  background-color: #6c96af !important;
}