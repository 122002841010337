.install-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

  /* Mostrar el botón solo en dispositivos móviles */
.install-button-container {
    display: none;
  }
  
 @media (max-width: 768px) {
    /* Mostrar el botón en dispositivos con un ancho máximo de 768px */
    .install-button-container {
      display: flex;
    }
}

#setup_button{
  margin-top: 3rem;
}

.custom-toast-container {
  width: 100%;
  box-shadow: 0px 0px 10px 1px #202c33;
}
.custom-toast-container h3 {
  text-align: center;
}
.custom-toast-container p {
  font-size: 1.2rem;
  text-align: justify;
}